import React, { useState } from "react";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import b from "../staticAssets/logo.png";
import PaypalLogo from "../staticAssets/paypal.svg";
import Visa from "../staticAssets/payment-card-visa.svg";
import MasterCard from "../staticAssets/payment-card-mastercard.svg";
import Amex from "../staticAssets/amex.svg";
import Discover from "../staticAssets/payment-card-discover.svg";
import Jcb from "../staticAssets/jcb.svg";

countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
const countryObj = countries.getNames("en", { select: "official" });

const countryArr = Object.entries(countryObj).map(([key, value]) => {
  return {
    label: value,
    value: key,
  };
});

const emptyAddress = {
  line1: "",
  postal_code: "",
  city: "",
  state: "",
  country: "",
};

const Card = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-8 w-8"
      viewBox="0 0 20 20"
      fill="	
  #ff7f50"
    >
      <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
      <path
        fillRule="evenodd"
        d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const Paypal = () => {
  return <PaypalLogo className="w-8 h-8" />;
};

const CheckoutAddressForm = (props) => {
  const {
    handleSubmit,
    setbillingShow,
    method,
    setmethod,
    modes,
    showOptions,
    setUsdToInrFlow,
    loading,
  } = props;
  const [name, setname] = useState("");
  const [address, setaddress] = useState({ ...emptyAddress });
  const [kk, setkk] = useState(showOptions ? 0 : 1);

  return (
    <>
      <div
        className="fixed z-50 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="fixed inset-0 bg-white transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          {kk == 0 && showOptions ? (
            <div
              className={`shadow-2xl inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle w-full lg:${
                kk == 0 ? "w-1/3" : "w-1/2"
              } relative`}
            >
              <div className="w-full bg-gradient-to-r from-blue-100 to-orange-100 relative h-16 flex items-center justify-center shadow">
                <h1 className="text-center text-lg">Payment Options</h1>
                <button
                  className="absolute left-0 p-4"
                  onClick={() => {
                    setbillingShow(false);
                    setmethod(-1);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                </button>
                <div className="absolute right-0 p-4">
                  <img loading="lazy" className="h-10 w-10" src={b} />
                </div>
              </div>
              <div className="p-10">
                <div className="flex items-center flex-col w-full">
                  <div className="flex flex-col">
                    {modes.map((mode, i) => {
                      return (
                        <label className="my-1 flex gap-2 items-center">
                          <input
                            type="radio"
                            name={mode.name}
                            key={i}
                            checked={method == mode.method}
                            onChange={(e) => {
                              setmethod(mode.method);
                              if (mode.id == "usdToInr") {
                                setUsdToInrFlow(true);
                              }
                            }}
                          />
                          <div className="flex gap-2 items-center">
                            {mode.name == "Paypal" ? <Paypal /> : <Card />}
                            <h4>{mode.name}</h4>
                          </div>
                        </label>
                      );
                    })}
                  </div>
                  {loading ? (
                    <button
                      type="button"
                      className="items-center justify-center self-center px-8 py-2 font-semibold text-sm shadow rounded-md text-white bg-blue-500 cursor-not-allowed"
                      disabled=""
                    >
                      <center>
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            stroke-width="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </center>
                    </button>
                  ) : (
                    <button
                      className={`${
                        method == -1 ? "bg-gray-300" : "bg-blue-500"
                      } text-white mt-4 px-4 py-1 rounded w-20`}
                      disabled={method == -1}
                      onClick={() => {
                        if (method == 2) {
                          setkk(1);
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      Next
                    </button>
                  )}
                </div>
              </div>
              <div className="bg-gray-100/50">
                <div className="flex justify-center items-center scale-[.6] gap-4">
                  <Visa className="h-16 w-16 object-contain scale-y-[.9]" />
                  <MasterCard className="h-16 w-16 object-contain scale-y-[.9]" />
                  <PaypalLogo className="h-[2.4rem] w-[3.6rem] object-contain mr-4 mb-2 scale-y-[1.1]" />
                  <Amex className="h-[2.4rem] w-[3.6rem] mr-4 mb-2 object-contain scale-[0.9]" />
                  <Discover className="h-16 w-16 object-contain scale-y-[0.9]" />
                  <Jcb className="mb-2" />
                </div>
              </div>
            </div>
          ) : (
            <div className="shadow-2xl inline-block align-bottom bg-white rounded-lg text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle w-full lg:w-1/2 relative">
              <div className="w-full bg-gradient-to-r from-blue-100 to-orange-100 h-16 relative flex items-center justify-center shadow">
                <h1 className="text-center text-lg">Billing Address</h1>
                <button
                  className="absolute left-0 p-4"
                  onClick={() => {
                    if (showOptions) {
                      setkk(0);
                    } else {
                      setbillingShow(false);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10 19l-7-7m0 0l7-7m-7 7h18"
                    />
                  </svg>
                </button>
                <div className="absolute right-0 p-4">
                  <img loading="lazy" className="h-10 w-10" src={b} />
                </div>
              </div>
              <div className="p-10">
                <form
                  className=""
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(name, address);
                  }}
                >
                  <div className="flex flex-col w-full">
                    <label className="text-sm">Name</label>
                    <input
                      type="text"
                      placeholder="Enter your name"
                      value={name}
                      className="h-10 border text-sm border-gray-200 focus:border-blue-500/50 focus:outline-blue-500/30 focus:outline focus:outline-[0.18rem] px-2 rounded text-gray-500"
                      required
                      onChange={(e) => {
                        setname(e.target.value);
                      }}
                    />
                    <label className="mt-6 text-sm mb-1">Address</label>
                    <input
                      type="text"
                      placeholder="Enter your address"
                      className="h-10 border text-sm border-gray-200 focus:border-blue-500/50 focus:outline-blue-500/30 focus:outline focus:outline-[0.18rem] px-2 rounded text-gray-500"
                      required
                      onChange={(e) => {
                        let a = address;
                        a.line1 = e.target.value;
                        setaddress({ ...a });
                      }}
                    />
                    <label className="mt-4 text-sm mb-1">Postal Code</label>
                    <input
                      type="text"
                      placeholder="Enter your postal/zip code"
                      className="h-10 border text-sm border-gray-200 focus:border-blue-500/50 focus:outline-blue-500/30 focus:outline focus:outline-[0.18rem] px-2 rounded text-gray-500"
                      required
                      onChange={(e) => {
                        let a = address;
                        a.postal_code = e.target.value;
                        setaddress({ ...a });
                      }}
                    />
                    <label className="mt-4 text-sm mb-1">City</label>
                    <input
                      type="text"
                      placeholder="Enter your city"
                      className="h-10 border text-sm border-gray-200 focus:border-blue-500/50 focus:outline-blue-500/30 focus:outline focus:outline-[0.18rem] px-2 rounded text-gray-500"
                      required
                      onChange={(e) => {
                        let a = address;
                        a.city = e.target.value;
                        setaddress({ ...a });
                      }}
                    />
                    <label className="mt-4 text-sm mb-1">State</label>
                    <input
                      type="text"
                      placeholder="Enter your state"
                      className="h-10 border text-sm border-gray-200 focus:border-blue-500/50 focus:outline-blue-500/30 focus:outline focus:outline-[0.18rem] px-2 rounded text-gray-500"
                      required
                      onChange={(e) => {
                        let a = address;
                        a.state = e.target.value;
                        setaddress({ ...a });
                      }}
                    />
                    <label className="mt-4 text-sm mb-1">Country</label>
                    <select
                      className="h-10 border text-sm border-gray-200 focus:border-blue-500/50 focus:outline-blue-500/30 focus:outline focus:outline-[0.18rem] px-2 rounded text-gray-500"
                      onChange={(e) => {
                        let a = address;
                        a.country = e.target.value;
                        setaddress({ ...a });
                      }}
                    >
                      <option value={""}>Select your country</option>
                      {countryArr.map(({ label, value }) => {
                        return (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <button
                    className="bg-blue-500 text-white mt-4 px-4 py-1 rounded w-20"
                    type="submit"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckoutAddressForm;
