import React, {useContext} from 'react'
import { AuthContext } from '../context/Auth';

function QuickAddCard({data}) {
    const { user} = useContext(AuthContext) || {user:{}};
    return (
        <div className="inline-block px-2">
            <div
                className="w-24 h-24 max-w-xs overflow-hidden rounded-lg shadow-md bg-white hover:shadow-lg hover:bg-orange-100/50 transition-shadow duration-300 ease-in-out flex flex-col items-center"
            >
                {data?.tag!=="" ? <div className="bg-[#FB5B1D] bg-opacity-85 h-5 w-full flex justify-center items-center">
                    <h2 className="text-white text-xs">
                        {data?.tag}
                    </h2>
                </div>: <div className="h-5 w-full flex justify-center items-center">
                    
                </div>}
                <h5 className="text-xl flex-1 flex items-center">{(user?.currencyType ==='usd'?'$':'₹') + data?.amount}</h5>
                <h4 className="text-[#FA942F] text-sm py-2">{data?.text}</h4>
            </div>
        </div>
    )
}

export default QuickAddCard
