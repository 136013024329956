import React, { useContext } from "react";

function PaymentDetailModal({ data }) {
  const { setshowDetail, paymentDetail, handleProceedToPayCheck, loading } = data;
  return (
    <div
      className="fixed z-50 inset-0 overflow-y-auto worksans"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center h-screen pt-4  pb-20 text-center sm:block">
        <div
          className="fixed inset-0 bg-black bg-opacity-80 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>

        <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full md:w-2/3 lg:w-1/3 relative">
          <button className="absolute top-0 right-0 p-4" onClick={()=>{setshowDetail(false);}}>X</button>
          
          <div className="mt-3 text-center sm:text-left flex flex-col items-start w-full px-4">
            <h4 className="mt-6 mb-2 font-lg text-black">Payment Details</h4>
            <div className="flex justify-between px-2 my-3 w-full">
              <h5 className="text-sm text-gray-500">Recharge Amount</h5>
              <h5 className="text-sm text-gray-500">
                {(paymentDetail?.currencyType === "usd" ? "$ " : "₹ ") + (paymentDetail?.amount ?? "")}
              </h5>
            </div>
            <div className=" px-3 w-full">
              <div className="h-[0.05rem] bg-gray-300"></div>
            </div>
            <div className="flex justify-between px-2 my-3 w-full">
              <h5 className="text-sm text-gray-500">You will get</h5>
              <h5 className="text-sm text-gray-500">
                {(paymentDetail?.currencyType === "usd" ? "$ " : "₹ ") + (paymentDetail?.finalAmount ?? "")}
              </h5>
            </div>
            <div className=" px-3 w-full">
              <div className="h-[0.05rem] bg-gray-300"></div>
            </div>
            <div className="flex justify-between px-2 my-3 w-full">
              <h5 className={`text-sm text-gray-500 ${paymentDetail?.isTaxApplied? "": "line-through"}`}>Gst @ {paymentDetail?.taxPercentage}%</h5>
              <h5 className={`text-sm text-gray-500 ${paymentDetail?.isTaxApplied? "": "line-through"}`}>
                {(paymentDetail?.currencyType === "usd" ? "$ " : "₹ ") + (paymentDetail?.taxAmount ?? "")}
              </h5>
            </div>
            <div className=" px-3 w-full">
              <div className="h-[0.05rem] bg-gray-300"></div>
            </div>
            <div className="px-2 w-full">

            {paymentDetail?.savings>0 &&<div className="px-2 bg-[#87BD36]/20 rounded-lg w-full py-[8px] my-4">
                <h6 className="text-[#72a12d] text-sm text-left">
                    🎉 Total Savings - {(paymentDetail?.currencyType === "usd" ? "$ " : "₹ ") + (paymentDetail?.savings ?? "")}
                    </h6>
            </div>}
            </div>
            <div className="flex justify-between px-2 my-2 w-full">
              <h5 className="text-sm text-black">Total Amount Payable</h5>
              <h5 className="text-sm text-black">
                {(paymentDetail?.currencyType === "usd" ? "$ " : "₹ ") + (paymentDetail?.payableAmount ?? "")}
              </h5>
            </div>
          </div>
          <div className="bg-white px-4 py-3 sm:px-6 flex justify-center w-full">
            {loading?<button className="mt-3 w-2/3 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-[#87BD36] text-base font-semibold text-white hover:bg-[#72a12d] focus:outline-none sm:mt-0 sm:ml-3 sm:text-sm">
                <svg
                    className="animate-spin h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      className="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>
            </button>:<button
              type="button"
              className="mt-3 w-2/3 inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-[#87BD36] text-base font-semibold text-white hover:bg-[#72a12d] focus:outline-none sm:mt-0 sm:ml-3 sm:text-sm"
              onClick={() => {
                handleProceedToPayCheck();
              }}
            >
              Proceed to Pay
            </button>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetailModal;
